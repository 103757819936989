import React from "react";
import { Link } from "gatsby";

import styles from "./imprint.module.css";

export default () => (
  <div className={styles.imprint}>
    <p className={styles.breadcrumb}>
      <Link to="/">janndriessen.com</Link> / imprint
      <br />
      <br />
      <br />
      <br />
    </p>
    <p>
      <h2>Jann Driessen - Media UG (haftungsbeschränkt)</h2>
      Rubensweg 9<br />
      31737 Rinteln
      <br />
      Deutschland
      <br />
      <a href="&#0109;&#097;&#0105;&#0108;&#0116;&#0111;&#058;&#0104;&#0101;&#0108;&#0108;&#0111;&#064;&#0106;&#097;&#0110;&#0110;&#0100;&#0114;&#0105;&#0101;&#0115;&#0115;&#0101;&#0110;&#046;&#099;&#0111;&#0109;">
        &#0104;&#0101;&#0108;&#0108;&#0111;&#064;&#0106;&#097;&#0110;&#0110;&#0100;&#0114;&#0105;&#0101;&#0115;&#0115;&#0101;&#0110;&#046;&#099;&#0111;&#0109;
      </a>
    </p>
    <p>
      Gerichtsstand: Stadthagen, Amtsgericht Stadthagen HRB 200562
      <br />
      Geschäftsführer: Jann Driessen
      <br /> USt-IdNr: DE271344672
    </p>
    <p>
      Verantwortlicher gemäß §55 Abs. 2 RStV
      <br />
      Jann Driessen
    </p>
    <p>
      <br />
      <br />
      <h3>Haftungsausschluss (Disclaimer)</h3>
    </p>
    <h4>Haftung für Inhalte</h4>
    <p>
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
      diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis
      10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
      übermittelte oder gespeicherte fremde Informationen zu überwachen oder
      nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
      hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
      Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
      Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
      einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
      entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
      entfernen.
    </p>
    <h4>Haftung für Links</h4>
    <p>
      Unser Angebot enthält Links zu externen Websites Dritter, auf deren
      Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
      Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
      Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
      auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
      Zeitpunkt der Verlinkung nicht erkennbar. Bei Bekanntwerden von
      Rechtsverletzungen werden wir derartige Links umgehend entfernen.
    </p>
    <h4>Urheberrecht</h4>
    <p>
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
      Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
      Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
      des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
      Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
      privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
      dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
      Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
      gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
      aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
      Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
      entfernen.
    </p>
    <h4>Streitschlichtung</h4>
    <p>
      Die Europäische Kommission stellt eine Plattform zur
      Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr.
      Die OS-Plattform kann ein Verbraucher für die außergerichtliche Beilegung
      einer Streitigkeit aus Online-Verträgen mit einem in der EU
      niedergelassenen Unternehmen nutzen.
    </p>
    <p>
      Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
      einer Verbraucherschlichtungsstelle teilzunehmen.
    </p>
  </div>
);
